.seat-map-container {
  .seat-map {
    width: 100%;
    .cinema-screen-container {
      position: relative;
      text-align: center;

      .cinema-screen {
        margin: 0 5%;

        @media (min-width: $break-md) {
          margin: 0 80px;
        }

        &.cinema-screen-custom {
          @media (min-width: $break-md) {
            margin: 0 120px;
          }
        }

        .cinema-screen-image {
          width: 100%;
          display: block;
          margin: 0;
          padding: 0;
          -webkit-clip-path: polygon(
            0 0,
            100% 0,
            calc(100% - 45px) 100%,
            45px 100%
          );
          clip-path: polygon(0 0, 100% 0, calc(100% - 45px) 100%, 45px 100%);
          height: 120px;
          position: relative;

          @media (min-width: $break-xxl) {
            height: 11vh;
            width: 85%;
            margin: 0 auto;
          }
        }
      }

      .label {
        position: absolute;
        font-size: 0.75rem;
        top: 6%;
        width: 100%;
        letter-spacing: 0.6rem;

        @media (min-width: $break-sm) {
          top: 12%;
          font-size: 0.8rem;
        }

        @media (min-width: $break-lg) {
          top: 8%;
          font-size: 1rem;
        }
      }
    }

    .back-of-theater {
      border-top-width: 1px;
      border-top-style: solid;
      text-align: center;
      width: 50%;
      margin: 0 auto;
    }

    .seats-container {
      position: relative;

      .zoom-wrapper {
        position: relative;
        width: 100%;

        .kvfysmfp {
          width: 100%;
          height: 100%;

          .zoom-content {
            width: 100%;
            min-height: '250px';

            .strategy-wrapper {
              left: 0;
              position: relative;
              padding: 0;

              &.coordinate-strategy {
                .seat-coordinate-containers {
                  margin: 0;

                  .seat-container {
                    position: absolute;
                  }
                }
              }

              &.grid-strategy {
                .seat-row-wrapper {
                  margin: 0;

                  .row-label {
                    line-height: 1rem;
                    max-width: 40px;
                    min-width: 40px;
                  }

                  .seat-column {
                    position: relative;

                    &.area-top {
                      padding-top: 24px;
                    }

                    .area-label {
                      white-space: nowrap;
                      top: 8px;
                      left: 8px;
                      z-index: 10000;
                      font-size: 1rem;

                      @media (max-width: $break-md) {
                        font-size: small;
                        top: 12%;
                        left: 24%;
                      }
                    }

                    &.area1,
                    &.area2,
                    &.area3 {
                      border-width: 1px;
                      border-style: none;

                      &.bt {
                        border-top-style: solid;
                      }

                      &.bb {
                        border-bottom-style: solid;
                      }

                      &.bl {
                        border-left-style: solid;
                      }

                      &.br {
                        border-right-style: solid;
                      }
                    }
                  }
                }
              }

              .seat-button-container {
                position: relative;
                border-radius: 0;

                .seat-button {
                  border: none;
                  background: none;
                  padding: 0;

                  &:disabled {
                    pointer-events: all;
                  }

                  &.unavailable {
                    opacity: 1;
                  }

                  &.preview {
                    cursor: default;
                  }

                  .svg-container {
                    &.seat-prohibited {
                      opacity: 0.4;
                    }

                    svg {
                      overflow: visible;
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .seat-id {
                    align-items: center;
                    bottom: 0;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 1px;
                  }
                }
              }
            }
          }
        }
      }

      .zoom-buttons-container {
        position: absolute;
        right: 0;
        top: calc(0% - 16px);
        z-index: 1010;
        overflow: visible;
        margin-right: 14px;

        .zoom-button {
          overflow: visible;
          border-radius: 50%;
          padding: 0.5rem;
          border: none;
        }

        svg {
          width: 45px;
        }
      }
    }

    .seating-legend {
      min-height: 20px;

      .legend-icon {
        cursor: default;

        svg {
          height: 20px;
          margin: 0;
          width: unset;
          cursor: default;

          .a:hover {
            cursor: default;
          }
        }
      }

      .disabled {
        opacity: 0.4;
      }
    }

    @media (max-width: $break-md) {
      .seating-legend.mobile {
        font-size: 0.8rem;
      }
    }
  }
}
